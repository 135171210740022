// Generated by purs version 0.13.8
"use strict";
var Control_Category = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Category.purs");
var Data_Function = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Function.purs");
var IxDiscard = function (idiscard) {
    this.idiscard = idiscard;
};
var IxBind = function (IxApply0, ibind) {
    this.IxApply0 = IxApply0;
    this.ibind = ibind;
};
var idiscard = function (dict) {
    return dict.idiscard;
};
var ibind = function (dict) {
    return dict.ibind;
};
var ibindFlipped = function (dictIxBind) {
    return Data_Function.flip(ibind(dictIxBind));
};
var ijoin = function (dictIxBind) {
    return function (m) {
        return ibind(dictIxBind)(m)(Control_Category.identity(Control_Category.categoryFn));
    };
};
var ixDiscardUnit = new IxDiscard(function (dictIxBind) {
    return ibind(dictIxBind);
});
var composeiKleisliFlipped = function (dictIxBind) {
    return function (f) {
        return function (g) {
            return function (a) {
                return ibindFlipped(dictIxBind)(f)(g(a));
            };
        };
    };
};
var composeiKleisli = function (dictIxBind) {
    return function (f) {
        return function (g) {
            return function (a) {
                return ibind(dictIxBind)(f(a))(g);
            };
        };
    };
};
module.exports = {
    IxBind: IxBind,
    ibind: ibind,
    ibindFlipped: ibindFlipped,
    composeiKleisli: composeiKleisli,
    composeiKleisliFlipped: composeiKleisliFlipped,
    IxDiscard: IxDiscard,
    idiscard: idiscard,
    ixDiscardUnit: ixDiscardUnit
};

