// Generated by purs version 0.13.8
"use strict";
var $foreign = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM.js");
var Control_Applicative = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Applicative.purs");
var Data_Function_Uncurried = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/functions/v4.0.0/src/Data/Function/Uncurried.purs");
var Data_Maybe = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/maybe/v4.0.1/src/Data/Maybe.purs");
var Data_Nullable = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/nullable/v4.1.1/src/Data/Nullable.purs");
var Data_Unit = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Unit.purs");
var Effect = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/effect/v2.0.1/src/Effect.purs");
var Effect_Exception = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/exceptions/v4.0.0/src/Effect/Exception.purs");
var Effect_Uncurried = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/effect/v2.0.1/src/Effect/Uncurried.purs");
var Unsafe_Coerce = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/unsafe-coerce/v4.0.0/src/Unsafe/Coerce.purs");
var unmount = Effect_Uncurried.runEffectFn1($foreign.unmountComponentAtNode_);
var text = Unsafe_Coerce.unsafeCoerce;
var render$prime = Effect_Uncurried.runEffectFn3($foreign.render_);
var render = function (jsx) {
    return function (node) {
        return render$prime(jsx)(node)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
};
var hydrate$prime = Effect_Uncurried.runEffectFn3($foreign.hydrate_);
var hydrate = function (jsx) {
    return function (node) {
        return hydrate$prime(jsx)(node)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
};
var findDOMNode = function (instance_) {
    return Effect_Exception["try"](function __do() {
        var node = $foreign.findDOMNode_(instance_);
        var v = Data_Nullable.toMaybe(node);
        if (v instanceof Data_Maybe.Nothing) {
            return Effect_Exception["throw"]("Node not found.")();
        };
        if (v instanceof Data_Maybe.Just) {
            return v.value0;
        };
        throw new Error("Failed pattern match at React.Basic.DOM (line 97, column 3 - line 99, column 22): " + [ v.constructor.name ]);
    });
};
var createPortal = Data_Function_Uncurried.runFn2($foreign.createPortal_);
module.exports = {
    render: render,
    "render'": render$prime,
    hydrate: hydrate,
    "hydrate'": hydrate$prime,
    unmount: unmount,
    findDOMNode: findDOMNode,
    createPortal: createPortal,
    text: text
};

