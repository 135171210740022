// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Applicative.purs");
var Control_Apply = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Apply.purs");
var Control_Bind = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Bind.purs");
var Control_Monad = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Monad.purs");
var Data_Eq = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Eq.purs");
var Data_Functor = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Functor.purs");
var Data_Monoid = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Monoid.purs");
var Data_Ord = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Ord.purs");
var Data_Semigroup = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Semigroup.purs");
var Data_Semiring = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Semiring.purs");
var Data_Show = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Show.purs");
var Multiplicative = function (x) {
    return x;
};
var showMultiplicative = function (dictShow) {
    return new Data_Show.Show(function (v) {
        return "(Multiplicative " + (Data_Show.show(dictShow)(v) + ")");
    });
};
var semigroupMultiplicative = function (dictSemiring) {
    return new Data_Semigroup.Semigroup(function (v) {
        return function (v1) {
            return Data_Semiring.mul(dictSemiring)(v)(v1);
        };
    });
};
var ordMultiplicative = function (dictOrd) {
    return dictOrd;
};
var monoidMultiplicative = function (dictSemiring) {
    return new Data_Monoid.Monoid(function () {
        return semigroupMultiplicative(dictSemiring);
    }, Data_Semiring.one(dictSemiring));
};
var functorMultiplicative = new Data_Functor.Functor(function (f) {
    return function (m) {
        return f(m);
    };
});
var eqMultiplicative = function (dictEq) {
    return dictEq;
};
var eq1Multiplicative = new Data_Eq.Eq1(function (dictEq) {
    return Data_Eq.eq(eqMultiplicative(dictEq));
});
var ord1Multiplicative = new Data_Ord.Ord1(function () {
    return eq1Multiplicative;
}, function (dictOrd) {
    return Data_Ord.compare(ordMultiplicative(dictOrd));
});
var boundedMultiplicative = function (dictBounded) {
    return dictBounded;
};
var applyMultiplicative = new Control_Apply.Apply(function () {
    return functorMultiplicative;
}, function (v) {
    return function (v1) {
        return v(v1);
    };
});
var bindMultiplicative = new Control_Bind.Bind(function () {
    return applyMultiplicative;
}, function (v) {
    return function (f) {
        return f(v);
    };
});
var applicativeMultiplicative = new Control_Applicative.Applicative(function () {
    return applyMultiplicative;
}, Multiplicative);
var monadMultiplicative = new Control_Monad.Monad(function () {
    return applicativeMultiplicative;
}, function () {
    return bindMultiplicative;
});
module.exports = {
    Multiplicative: Multiplicative,
    eqMultiplicative: eqMultiplicative,
    eq1Multiplicative: eq1Multiplicative,
    ordMultiplicative: ordMultiplicative,
    ord1Multiplicative: ord1Multiplicative,
    boundedMultiplicative: boundedMultiplicative,
    showMultiplicative: showMultiplicative,
    functorMultiplicative: functorMultiplicative,
    applyMultiplicative: applyMultiplicative,
    applicativeMultiplicative: applicativeMultiplicative,
    bindMultiplicative: bindMultiplicative,
    monadMultiplicative: monadMultiplicative,
    semigroupMultiplicative: semigroupMultiplicative,
    monoidMultiplicative: monoidMultiplicative
};

