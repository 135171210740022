// Generated by purs version 0.13.8
"use strict";
var Data_Function = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Function.purs");
var Data_Unit = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Unit.purs");
var IxFunctor = function (imap) {
    this.imap = imap;
};
var imap = function (dict) {
    return dict.imap;
};
var ivoid = function (dictIxFunctor) {
    return imap(dictIxFunctor)(Data_Function["const"](Data_Unit.unit));
};
var ivoidLeft = function (dictIxFunctor) {
    return function (f) {
        return function (x) {
            return imap(dictIxFunctor)(Data_Function["const"](x))(f);
        };
    };
};
var ivoidRight = function (dictIxFunctor) {
    return function (x) {
        return imap(dictIxFunctor)(Data_Function["const"](x));
    };
};
module.exports = {
    imap: imap,
    IxFunctor: IxFunctor,
    ivoid: ivoid,
    ivoidRight: ivoidRight,
    ivoidLeft: ivoidLeft
};

