// Generated by purs version 0.13.8
"use strict";
var $foreign = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks.js");
var Control_Bind = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Bind.purs");
var Data_Eq = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Eq.purs");
var Data_Function = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Function.purs");
var Data_Function_Uncurried = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/functions/v4.0.0/src/Data/Function/Uncurried.purs");
var Data_Functor = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Functor.purs");
var Data_Newtype = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/newtype/v3.0.0/src/Data/Newtype.purs");
var Data_Nullable = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/nullable/v4.1.1/src/Data/Nullable.purs");
var Data_Tuple = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/tuples/v5.1.0/src/Data/Tuple.purs");
var Effect = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/effect/v2.0.1/src/Effect.purs");
var Effect_Uncurried = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/effect/v2.0.1/src/Effect/Uncurried.purs");
var React_Basic_Hooks_Internal = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks/Internal.purs");
var Unsafe_Coerce = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/unsafe-coerce/v4.0.0/src/Unsafe/Coerce.purs");
var Unsafe_Reference = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/unsafe-reference/v3.0.1/src/Unsafe/Reference.purs");
var UnsafeReference = function (x) {
    return x;
};
var writeRef = Effect_Uncurried.runEffectFn2($foreign.writeRef_);
var useState = function (initialState) {
    return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useState_(Data_Function_Uncurried.mkFn2(Data_Tuple.Tuple.create), initialState);
    });
};
var useRef = function (initialValue) {
    return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useRef_(initialValue);
    });
};
var useReducer = function (initialState) {
    return function (reducer) {
        return React_Basic_Hooks_Internal.unsafeHook(function () {
            return $foreign.useReducer_(Data_Function_Uncurried.mkFn2(Data_Tuple.Tuple.create), Data_Function_Uncurried.mkFn2(reducer), initialState);
        });
    };
};
var useMemo = function (dictEq) {
    return function (key) {
        return function (computeA) {
            return React_Basic_Hooks_Internal.unsafeHook(function () {
                return $foreign.useMemo_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), key, computeA);
            });
        };
    };
};
var useLayoutEffect = function (dictEq) {
    return function (keys) {
        return function (effect) {
            return React_Basic_Hooks_Internal.unsafeHook(function () {
                return $foreign.useLayoutEffect_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), keys, effect);
            });
        };
    };
};
var useEqCache = function (dictEq) {
    return function (a) {
        return React_Basic_Hooks_Internal.unsafeHook(function () {
            return $foreign.useEqCache_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), a);
        });
    };
};
var useEffect = function (dictEq) {
    return function (key) {
        return function (effect) {
            return React_Basic_Hooks_Internal.unsafeHook(function () {
                return $foreign.useEffect_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), key, effect);
            });
        };
    };
};
var useContext = function (context) {
    return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useContext_(context);
    });
};
var useCallback = function (dictEq) {
    return function (key) {
        return function (computeA) {
            return React_Basic_Hooks_Internal.unsafeHook(function () {
                return $foreign.useCallback_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), key, computeA);
            });
        };
    };
};
var unsafeReactFunctionComponent = Unsafe_Coerce.unsafeCoerce;
var unsafeDiscardRenderEffects = Unsafe_Coerce.unsafeCoerce;
var unsafeComponent = function (dictLacks) {
    return function (dictLacks1) {
        return function (name) {
            return function (renderFn) {
                var c = unsafeReactFunctionComponent(function (props) {
                    return unsafeDiscardRenderEffects(renderFn(props))();
                });
                return function () {
                    return $foreign.unsafeSetDisplayName(name, c);
                };
            };
        };
    };
};
var readRef = Effect_Uncurried.runEffectFn1($foreign.readRef_);
var readRefMaybe = function (a) {
    return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(readRef(a));
};
var renderRefMaybe = function (a) {
    return React_Basic_Hooks_Internal.unsafeRenderEffect(readRefMaybe(a));
};
var renderRef = function (ref) {
    return React_Basic_Hooks_Internal.unsafeRenderEffect(readRef(ref));
};
var reactChildrenFromArray = Unsafe_Coerce.unsafeCoerce;
var newtypeUnsafeReference = new Data_Newtype.Newtype(function (n) {
    return n;
}, UnsafeReference);
var memo = Data_Function.flip(Control_Bind.bind(Effect.bindEffect))(Effect_Uncurried.runEffectFn1($foreign.memo_));
var eqUnsafeReference = new Data_Eq.Eq(Unsafe_Reference.unsafeRefEq);
var componentWithChildren = function (dictLacks) {
    return function (dictLacks1) {
        return unsafeComponent()();
    };
};
var component = function (dictLacks) {
    return function (dictLacks1) {
        return function (dictLacks2) {
            return unsafeComponent()();
        };
    };
};
var componentFromHook = function (dictLacks) {
    return function (dictLacks1) {
        return function (dictLacks2) {
            return function (name) {
                return function (propsToHook) {
                    return component()()()(name)(function (props) {
                        return Data_Functor.map(React_Basic_Hooks_Internal.functorRender)(props.render)(propsToHook(props));
                    });
                };
            };
        };
    };
};
module.exports = {
    component: component,
    componentWithChildren: componentWithChildren,
    componentFromHook: componentFromHook,
    memo: memo,
    useState: useState,
    useEffect: useEffect,
    useLayoutEffect: useLayoutEffect,
    useReducer: useReducer,
    readRef: readRef,
    readRefMaybe: readRefMaybe,
    writeRef: writeRef,
    reactChildrenFromArray: reactChildrenFromArray,
    renderRef: renderRef,
    renderRefMaybe: renderRefMaybe,
    useRef: useRef,
    useContext: useContext,
    useMemo: useMemo,
    useCallback: useCallback,
    useEqCache: useEqCache,
    UnsafeReference: UnsafeReference,
    newtypeUnsafeReference: newtypeUnsafeReference,
    eqUnsafeReference: eqUnsafeReference,
    reactChildrenToArray: $foreign.reactChildrenToArray,
    displayName: $foreign.displayName
};

