// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Applicative.purs");
var Gatsby_Link = require("/home/runner/work/jackpooley.io/jackpooley.io/app/Gatsby/Link.purs");
var React_Basic_DOM = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM.purs");
var React_Basic_DOM_Generated = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM/Generated.purs");
var React_Basic_Hooks = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks.purs");
var React_Basic_Hooks_Internal = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks/Internal.purs");
var Type_Equality = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/type-equality/v3.0.0/src/Type/Equality.purs");
var mkNavigation = (function () {
    var render = function (props) {
        return React_Basic_DOM_Generated.nav()({
            className: "text-gray-800 w-full flex items-center justify-between py-3 px-4",
            children: [ React_Basic_DOM_Generated.div()({
                className: "font-extrabold text-xl",
                children: [ React_Basic_DOM.text("Gatsby.js with Purescript Example") ]
            }), React_Basic_DOM_Generated.ul()({
                className: "flex-grow flex justify-end items-center",
                children: [ React_Basic_DOM_Generated.li()({
                    children: [ Gatsby_Link.link()({
                        to: "/",
                        children: [ React_Basic_DOM_Generated.a()({
                            className: "inline-block py-2 px-4 font-bold",
                            children: [ React_Basic_DOM.text("Home") ]
                        }) ]
                    }) ]
                }), React_Basic_DOM_Generated.li()({
                    children: [ Gatsby_Link.link()({
                        to: "/about",
                        children: [ React_Basic_DOM_Generated.a()({
                            className: "inline-block py-2 px-4 font-bold",
                            children: [ React_Basic_DOM.text("About") ]
                        }) ]
                    }) ]
                }), React_Basic_DOM_Generated.li()({
                    children: [ Gatsby_Link.link()({
                        to: "/profile",
                        children: [ React_Basic_DOM_Generated.a()({
                            className: "inline-block py-2 px-4 font-bold",
                            children: [ React_Basic_DOM.text("Profile") ]
                        }) ]
                    }) ]
                }) ]
            }) ]
        });
    };
    return React_Basic_Hooks.component()()()("Navigation")(function (props) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(render(props));
    });
})();
module.exports = {
    mkNavigation: mkNavigation
};

