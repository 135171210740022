// Generated by purs version 0.13.8
"use strict";
var $foreign = require("/home/runner/work/jackpooley.io/jackpooley.io/app/Gatsby/Link.js");
var React_Basic = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic.purs");
var link = function (dictUnion) {
    return function (attrs) {
        return React_Basic.element($foreign.linkComponent)(attrs);
    };
};
module.exports = {
    link: link,
    linkComponent: $foreign.linkComponent
};

