// Generated by purs version 0.13.8
"use strict";
var Control_Category = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Category.purs");
var Data_Function = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Function.purs");
var Data_Functor_Indexed = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/indexed-monad/v1.2.0/src/Data/Functor/Indexed.purs");
var IxApply = function (IxFunctor0, iapply) {
    this.IxFunctor0 = IxFunctor0;
    this.iapply = iapply;
};
var iapply = function (dict) {
    return dict.iapply;
};
var iapplyFirst = function (dictIxApply) {
    return function (a) {
        return function (b) {
            return iapply(dictIxApply)(Data_Functor_Indexed.imap(dictIxApply.IxFunctor0())(Data_Function["const"])(a))(b);
        };
    };
};
var iapplySecond = function (dictIxApply) {
    return function (a) {
        return function (b) {
            return iapply(dictIxApply)(Data_Functor_Indexed.imap(dictIxApply.IxFunctor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
        };
    };
};
module.exports = {
    IxApply: IxApply,
    iapply: iapply,
    iapplyFirst: iapplyFirst,
    iapplySecond: iapplySecond
};

