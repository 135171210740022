// Generated by purs version 0.13.8
"use strict";
var $foreign = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM/Internal.js");
var Data_Monoid = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Monoid.purs");
var Data_Semigroup = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Data/Semigroup.purs");
var Unsafe_Coerce = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/unsafe-coerce/v4.0.0/src/Unsafe/Coerce.purs");
var unsafeCreateDOMComponent = Unsafe_Coerce.unsafeCoerce;
var semigroupCSS = new Data_Semigroup.Semigroup(function (a) {
    return function (b) {
        return $foreign.mergeStyles([ b, a ]);
    };
});
var css = Unsafe_Coerce.unsafeCoerce;
var monoidCSS = new Data_Monoid.Monoid(function () {
    return semigroupCSS;
}, css({}));
module.exports = {
    css: css,
    unsafeCreateDOMComponent: unsafeCreateDOMComponent,
    semigroupCSS: semigroupCSS,
    monoidCSS: monoidCSS,
    mergeStyles: $foreign.mergeStyles
};

